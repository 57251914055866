import React from "react"
import Layout from "src/components/common/Layout"
import HeroSection from "src/components/page/home/HeroSection"
import ChannelsSection from "src/components/common/ChannelsSection"
import ScheduleCarouselSection from "src/components/page/home/ScheduleCarouselSection"
import ShowsSection from "src/components/page/home/ShowsSection"
import YoutubeSection from "src/components/page/home/YoutubeSection"
import ProgrammingSection from "src/components/page/home/ProgrammingSection"

const HomePage = () => {
  return (
    <Layout>
      <HeroSection />
      {/* <ScheduleCarouselSection /> */}
      <ChannelsSection />
      {/* <ShowsSection /> */}
      {/* <YoutubeSection /> */}
      {/* <ProgrammingSection /> */}
    </Layout>
  )
}

export default HomePage
