import { StaticImage } from "gatsby-plugin-image"
import React, { useEffect, useRef, useState } from "react"
import { StyledSectionContainer } from "src/components/common/StyledSectionContainer"
import YoutubeButton from "src/components/common/YoutubeButton"
import styled from "styled-components"

const YoutubeSection = () => {
  let contentHeightRef = useRef(0)

  const [contentHeight, setContentHeight] = useState(0)

  useEffect(() => {
    setContentHeight(contentHeightRef.current.offsetHeight)
  }, [])

  return (
    <StyledYoutubeSection contentHeight={contentHeight}>
      <h2>Maximum Effort On YouTube</h2>
      <div className="youtube-section">
        <div className="youtube-section__image">
          {/* <img
            src="https://assets-imgx.fubo.tv/marketing/lp/images/fsn/youtube-header.jpg"
            alt="Maximum Effort Channel YouTube Channel"
          /> */}
          <div style={{ height: "300px", border: "1px solid #000" }}></div>
        </div>
        <div className="youtube-section__content" ref={contentHeightRef}>
          <div className="youtube-section__logo">
            <StaticImage
              src="../../../../assets/images/mec-logo-dark.png"
              alt="Maximum Effort Channel logo"
            />
          </div>
          <div className="youtube-section__text">
            <div className="youtube-section__title">
              <h3>@MaximumEffort</h3>
              <div className="youtube-section__check-icon">
                <svg height="100%" width="100%" viewBox="0 0 32 32">
                  <g fill="#757575">
                    <path d="M16,0C7.164,0,0,7.164,0,16s7.164,16,16,16s16-7.164,16-16S24.836,0,16,0z M13.52,23.383 L6.158,16.02l2.828-2.828l4.533,4.535l9.617-9.617l2.828,2.828L13.52,23.383z"></path>
                  </g>
                </svg>
              </div>
            </div>
            <div className="youtube-section__sub">
              <span>185K+ subscribers</span>
              <span>72,000,000+ Views</span>
              <span>1000+ Videos</span>
            </div>
          </div>
          <div className="youtube-section__youtube-button">
            <YoutubeButton
              link="https://www.youtube.com/c/fubosports"
              target="_blank"
            />
          </div>
        </div>
      </div>
      <p>
        Missed an episode from one of our Maximum Effort original series? You
        can find them all on YouTube and watch them whenever you like.
      </p>
    </StyledYoutubeSection>
  )
}

export default YoutubeSection

const StyledYoutubeSection = styled(StyledSectionContainer)`
  position: relative;
  padding-left: 60px;
  padding-right: 60px;
  @media (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  h2 {
    text-align: center;
  }
  .youtube-section {
    max-width: 1030px;
    @media (min-width: 768px) {
      margin-bottom: -${props => props.contentHeight / 2}px;
    }
    .youtube-section__image {
      width: 100%;
      border-radius: 11px;
      overflow: hidden;
      img {
        width: 100%;
        display: block;
      }
    }
    .youtube-section__content {
      position: relative;
      background: #191a1a;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;
      gap: 20px;
      border-radius: 0px 0px 50px 50px;
      @media (max-width: 767px) {
        flex-direction: column;
      }
      @media (min-width: 768px) {
        transform: translateY(-${props => props.contentHeight / 2}px);
      }
      .youtube-section__logo {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 15px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          display: block;
        }
      }
      .youtube-section__text {
        display: flex;
        flex-direction: column;
        gap: 6px;
        margin-right: 20px;
        .youtube-section__title {
          display: flex;
          gap: 10px;
          .youtube-section__check-icon {
            width: 20px;
          }
        }
        .youtube-section__sub {
          display: flex;
          color: rgba(167, 170, 170, 1);
          span {
            + span {
              &:before {
                content: "•";
                margin: 0 10px;
              }
            }
          }
        }
      }
    }
  }
`
