const springServeVast =
  "https://vid.springserve.com/vast/493180?w=1920&h=1080&cb={{CACHEBUSTER}}&ip={{IP}}&ua={{USER_AGENT}}&pod_max_dur=15&pod_ad_slots=1"
const liveStreamSrc =
  "https://offplatform-midas.fubo.tv/v1/master/cf8aed8001539cb4cab4963801d5f2cda8d77a1e/production-sportsnet/mxef/master.m3u8?ads.partner=mxef"

// https://offplatform-midas.fubo.tv/v1/master/cf8aed8001539cb4cab4963801d5f2cda8d77a1e/production-sportsnet/mxef/master.m3u8?ads.partner=mxef&ads.dnt={DNT}&ads.us_privacy={US_PRIVACY}&ads.app_name={APP_NAME}&ads.vauth={VAUTH}&ads.bundle_id={APPBUNDLE}&ads.did={IFA}&ads.client_id={CLIENT_ID}&ads.gdpr={GDPR}&ads.gdpr_consent={GDPR_CONSENT}&ads.ua={UA}&ads.coppa={COPPA}&ads.channel_name=MEC&ads.station_id=132038

const liveStreamType = "application/x-mpegURL"

const imgxBaseUrl = "https://assets-imgx.fubo.tv/marketing/lp/images/fsn/"

export { springServeVast, liveStreamSrc, liveStreamType }
