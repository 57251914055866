import { Link, graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { StyledSectionContainer } from "src/components/common/StyledSectionContainer"
import { seriesData } from "src/components/constants/seriesData"
import styled from "styled-components"

const ShowsSection = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { sourceInstanceName: { eq: "images" } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `)

  const imageMap = data.allFile.edges.reduce((acc, edge) => {
    if (edge.node.childImageSharp) {
      acc[edge.node.relativePath] = edge.node.childImageSharp.gatsbyImageData
    }
    return acc
  }, {})

  return (
    <StyledShowsSection>
      <h2>
        <span>
          <StaticImage
            src="../../../../../src/assets/images/mec-logo-white.png"
            alt="Maximum Effort Channel Logo"
          />
        </span>{" "}
        Original Shows
      </h2>
      <div className="shows-section">
        {seriesData().map((item, index) => {
          const imageData = imageMap[item.imageV]
          return (
            <div className="show-item">
              {/* <Link className="image" to="/series" key={index}> */}
              {imageData ? (
                <GatsbyImage image={getImage(imageData)} alt={item.name} />
              ) : null}
              {/* </Link> */}
              <h4>{item.name}</h4>
              <p>{item.description}</p>
            </div>
          )
        })}
      </div>
      <p className="shows-subtitle">
        We’re bringing game changers to the world of original TV. Watch our
        outstanding lineup of talent every day on Maximum Effort Channel.
      </p>
    </StyledShowsSection>
  )
}

export default ShowsSection

const StyledShowsSection = styled(StyledSectionContainer)`
  background: #000;
  color: #fff;
  position: relative;
  padding-left: 60px;
  padding-right: 60px;
  @media (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  &::before {
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    background: radial-gradient(#fff, transparent 70%);
    opacity: 0.35;
  }
  > * {
    z-index: 1;
  }
  h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    span {
      img {
        display: inline-block;
        width: 60px;
      }
    }
  }
  .shows-section {
    max-width: 1200px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    .show-item {
      display: flex;
      flex-direction: column;
      ${"" /* justify-content: center; */}
      ${"" /* align-items: center; */}
      gap: 10px;
    }
    h4 {
      font-size: 20px;
      line-height: normal;
      text-align: unset;
    }
    p {
      font-size: 16px;
      line-height: normal;
      text-align: unset;
      color: #a7aaaa;
    }
    .image {
      width: 100%;
      border-radius: 10px;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    @media (max-width: 600px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .shows-subtitle {
    margin-top: 10px;
    color: #a7aaaa;
  }
`
